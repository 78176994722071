import { SettingsOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { TitleProps } from '@refinedev/core'

export const Title: React.FC<TitleProps> = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginY: 1,
            }}
        >
            <SettingsOutlined sx={{ color: 'white' }} />
        </Box>
    )
}
